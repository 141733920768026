import { QRCode, Row } from 'antd';
import moment from 'moment';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import giftCardSelectors from './services/selectors';

const BuyGiftCardSuccess = ({ }) => {
  const date = useMemo(() => moment(), []);
  const result = giftCardSelectors.successInfo();

  if (!result) return <Navigate to={'/'} replace />;

  return (
    <>
      <Page>
        <div className='box' style={{
          'borderRadius': '12px',
          'background': '#FFF',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
          padding: '24px',
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="28.5" cy="28.6191" r="28" fill="#E6F4FF" />
              <path d="M28.0003 15.2856C20.6537 15.2856 14.667 21.2723 14.667 28.619C14.667 35.9656 20.6537 41.9523 28.0003 41.9523C35.347 41.9523 41.3337 35.9656 41.3337 28.619C41.3337 21.2723 35.347 15.2856 28.0003 15.2856ZM34.3737 25.5523L26.8137 33.1123C26.627 33.299 26.3737 33.4056 26.107 33.4056C25.8403 33.4056 25.587 33.299 25.4003 33.1123L21.627 29.339C21.2403 28.9523 21.2403 28.3123 21.627 27.9256C22.0137 27.539 22.6537 27.539 23.0403 27.9256L26.107 30.9923L32.9603 24.139C33.347 23.7523 33.987 23.7523 34.3737 24.139C34.7603 24.5256 34.7603 25.1523 34.3737 25.5523Z" fill="#0561AE" />
            </svg>
            <p
              style={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                color: '#1D2129',
                textAlign: 'center',
                marginTop: '16px',
              }}
            >Payment Success!</p>
          </div>
          <div style={{ margin: '32px 0' }}>
            <div className='circle' />
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
            }} />
            <div className='circle' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Auth Code:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.authCode}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Trans Num:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.transNum}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Date:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{date.format('ddd, MM-DD-YYYY')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Time:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{date.format('HH:mm A')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Payment Method:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.paymentMethod || 'Credit Card'}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Name On Card:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.customerName || ''}</span>
            </div>
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
              margin: '2px 0',
            }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Amount:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{formatCurrency(parseFloat(String(result?.amount ?? '')))}</span>
            </div>
          </div>
          <div style={{ height: 1, width: '100%', background: '#DADADA', margin: '16px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>QR Code:</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <QRCode size={75} value={result?.giftCardCode?.toString() || ''} />
              <div>
                {result?.giftCardCode}
              </div>
            </div>
          </div>
        </div>
      </Page >
      <Row style={{ padding: '0 1rem', paddingBottom: '1rem' }} align={'middle'} justify={'center'}>
        <button className='btn btn-primary' onClick={() => window.location.replace('https://citronorganicnails.com/')}>
          <p className="text">{'Back to home'}</p>
        </button>
      </Row>
    </>
  );
};

export default BuyGiftCardSuccess;

const Page = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  .box {
    width: 100%;
    max-width: 20rem;
  }
  @media only screen and (max-width: 600px) {
    .box {
      width: 100%;
      max-width: unset;
    } 
  }
`;