import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { v4 as uuid } from 'uuid';
import giftCardImg from '../img/giftCardImg.jpg';
const initialState: IState = {
  list: [{
    urlImage: giftCardImg,
    giftName: 'Gift card',
    id: uuid(),
    numberExpire: 0,
    templateId: uuid(),
  }],
  detail: null,
  bodyPayment: null,
  successInfo: null,
  configs: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getGiftCardDetail, (state) => {
        state.detail = {
          urlImage: giftCardImg,
          amounts: ['100', '200', '300'],
          giftName: 'Gift card',
          numberDayExpired: 0,
        };
      })
      .addCase(actions.getGiftCardToBuy.success, (state, { payload }) => {
        state.list = payload;
      })
      .addCase(actions.getGiftCardConfigs.success, (state, { payload }) => {
        state.configs = payload;
      })
      .addCase(actions.setPaymentInfo, (state, { payload }) => {
        state.bodyPayment = payload;
      })
      .addCase(actions.setSuccessInfo, (state, { payload }) => {
        state.successInfo = payload;
      })
      ;
  },
});

const giftCardServiceReducer = Slice.reducer;
export default giftCardServiceReducer;
