import { Button, Col, Form, Input, InputRef, Row } from 'antd';
import CurrencyInput from 'components/CurrencyInput';
import PhoneInput from 'components/PhoneInput';
import { useEffect, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import giftCardActions from './services/actions';
import giftCardSelectors from './services/selectors';
import { IBodyPaymentGiftCard } from './services/types/api';
import { Arrow, GiftCardItem, PageStyled } from './styles';
type IBuyGiftCardFormValues = {
  name: string;
  email: string;
  phone: string;
  amount: string;
  message: string;
  cardType: string;
  cardName: string;
  card: string;
  expire: string;
  cv: string;
};

const BuyGiftCardPage = () => {
  const inputNameRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const detail = giftCardSelectors.detail();

  useEffect(() => {

    setTimeout(() => { inputNameRef.current?.focus(); }, 100);
  }, []);

  const handleFinish = async (values: IBuyGiftCardFormValues) => {
    const phoneNumber = values.phone?.replace(/[^0-9]/g, '');
    const amount = parseFloat(values.amount || '');

    const _bodyPayment: IBodyPaymentGiftCard = {
      amount,
      customerName: values.name || '',
      email: values.email || null,
      giftCardTemplateId: id,
      message: values.message,
      phoneNumber: phoneNumber,
    };

    dispatch(giftCardActions.setPaymentInfo(_bodyPayment));
    navigate('pay');
    return;
  };

  const submit = () => form.submit();

  if (!detail) return <Navigate to={'/'} replace />;

  return (
    <PageStyled className='container'>
      <div className="top-bar">
        <button onClick={() => navigate('/')}>
          <Arrow />
        </button>
        <p className='title'>Happy Day Gift Card</p>
      </div>
      <div className='body'>
        <GiftCardItem className='btn gift-card-item'>
          <img src={detail?.urlImage} />
        </GiftCardItem>
        <Form form={form} onFinish={handleFinish} validateTrigger={['onSubmit']} layout='vertical'>
          <Row gutter={{ xs: 0, sm: 24 }}>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Your Name <span className='require-input'>(*)</span></>} name={'name'}
                rules={[{ required: true, message: 'Please enter your name' }]}
              >
                <Input autoFocus ref={inputNameRef} placeholder='Please enter...' tabIndex={1} type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'Email'} name={'email'}
                rules={[{ type: 'email' }]}
              >
                <Input placeholder='Please enter...' tabIndex={2} type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Phone <span className='require-input'>(*)</span></>} name={'phone'}
                rules={[{ required: true, message: 'Please enter your phone number' }]}
              >
                <PhoneInput tabIndex={3} type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Amount <span className='require-input'>(*)</span></>} name={'amount'}
                rules={[{ required: true, message: 'Please enter your amount' }]}
              >
                <CurrencyInput />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'Message'} name={'message'}>
                <Input.TextArea rows={3} tabIndex={5} inputMode='text' />
              </Form.Item>
            </Col>
          </Row>

          <div className='button-submit-sm'>
            <Row className='buttons'>
              <Button className='button' onClick={submit}>
                <p className="text">{'NEXT'}</p>
              </Button>
            </Row>
          </div>
          <div className="sticky-bottom">
            <Row className='buttons'>
              <Button className='button button-submit-xs' onClick={submit}>
                <p className="text">{'NEXT'}</p>
              </Button>
            </Row>
          </div>
        </Form>
      </div>
    </PageStyled>
  );
};

export default BuyGiftCardPage;
