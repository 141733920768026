import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  loading: true,
  shopData: null,
  giftCards: [],
  activeGiftCard: null,
  configs: null,
  loadingDetail: true,
  detail: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    activeGiftCard: (state, { payload }) => {
      state.activeGiftCard = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(actions.getTransactionDetail.fetch, (state) => {
        state.loadingDetail = true;
        state.detail = null;
      })
      .addCase(actions.getTransactionDetail.success, (state, { payload }) => {
        state.loadingDetail = false;
        state.detail = payload;
      })
      .addCase(actions.getTransactionDetail.fail, (state) => {
        state.loadingDetail = false;
        state.detail = null;
      })
      .addCase(actions.getGiftCardConfigs.success, (state, { payload }) => {
        state.configs = payload;
      })
      .addCase(actions.getListGiftCards.success, (state, { payload }) => {
        state.giftCards = payload;
      })
      .addCase(actions.getShopData.fetch, (state) => {
        state.loading = true;
        state.shopData = null;
      })
      .addCase(actions.getShopData.success, (state, { payload }) => {
        state.loading = false;
        state.shopData = payload;
      })
      .addCase(actions.getShopData.fail, (state) => {
        state.loading = false;
        state.shopData = null;
      })
      ;
  },
});

export const shopGiftCardUIActions = Slice.actions;
const shopGiftCardServiceReducer = Slice.reducer;
export default shopGiftCardServiceReducer;
