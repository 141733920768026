import { Col, Row, Spin } from 'antd';
import { LoadingFull } from 'components/Loading';
import { useNavigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import banner from '../img/banner.jpg';
import logo from '../img/logo.png';
import giftCardActions from '../services/actions';
import giftCardSelectors from '../services/selectors';
import { IGiftCardItem } from '../services/types/giftCard';
import { useEffect } from 'react';

function DejavooGiftCardListPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const giftCards = giftCardSelectors.list();
  const loading = giftCardSelectors.getListLoading();
  const loadingShop = shopSelectors.getShopInfoLoading();

  useEffect(() => {
    dispatch(giftCardActions.getGiftCardConfigs.fetch());
  }, []);

  const handleClickGiftCard = (o: IGiftCardItem) => () => {
    dispatch(giftCardActions.getGiftCardDetail());
    navigate(o.id);
  };

  if (loadingShop) return (<LoadingFull />);

  return (
    <PageStyled>
      <Banner className='banner'>
        <div className="box">
          <img src={logo} alt="" />
        </div>
      </Banner>
      <div className="section-title container">Gift Cards</div>
      <Spin spinning={loading}>
        <GiftCardList gutter={[10, 10]} style={{ margin: 0 }} className='container'>
          {
            giftCards.map(o => (
              <Col xs={20} sm={6} lg={6} key={o.id}>
                <GiftCardItem className='btn gift-card-item' onClick={handleClickGiftCard(o)}>
                  <img src={o.urlImage} />
                </GiftCardItem>
              </Col>
            ))
          }
        </GiftCardList>
      </Spin>
    </PageStyled>
  );
}

export default DejavooGiftCardListPage;

const PageStyled = styled.div`
  background: #F7F7F7;
  min-height: 100vh;
  .section-title {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .container {
    padding: 0 5%;
  }
  @media only screen and (max-width: 768px) {
    .banner .box {
      width: 80%;
      svg {
        width: 30%;
      }
      .title {
        font-size: 16px;
      }
      .info {
        font-size: 13px;
        padding: 0 8px;
      }
    }
    .section-title {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .container {
      padding: 0 8px;
      justify-content: center;
    }
    .gift-card-item {
      img {
        border-radius: 8px;
      }
      .content {
        text-align: left;
        padding: 0.5rem 0.5rem;
        .name {
          font-size: 12px;
        }
        .desc {
          font-size: 10px;
        }
      }
    }
  }
`;

const Banner = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: relative;
padding: 1.125rem 0;
background-repeat: no-repeat;
background-size: cover;

background-image: url(${banner});
background-repeat: no-repeat;
background-position: center;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(15px); 
    padding: 1rem 0;
    width: 40%;
    img {
      width: 80%;
      border-radius: 8px;
    }
  }

  .title {
    color: #FFF;
    text-align: center;
    font-family: Poller One;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const GiftCardList = styled(Row)``;

const GiftCardItem = styled.button`
border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 4px;
width: 100%;
  img {
    height: 150px;
    width: 100%;
    border-radius: 8px;
  }
  .ant-image {
    width: 100%; 
    height: 9.375rem;  
  }
  .content {
    padding: 0.5rem 1rem;
  }
  .name {
    color: var(--text-text-3, #1D2129);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .desc {
    color: var(--text-text-2, #566A7F);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    img {
      border-radius: 8px;
      height: 170px;
      width: 100%;
    }
  }
`;