import { Button, Col, Form, Input, InputRef, Row } from 'antd';
import { AxiosResponse } from 'axios';
import CurrencyInput from 'components/CurrencyInput';
import PhoneInput from 'components/PhoneInput';
import { useEffect, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { loadingOverlayRef } from 'services/UI/LoadingPage/LoadingOverlay';
import { formatCurrency } from 'utils/formatCurrency';
import shopGiftCardSelectors from './services/selectors';
import { IBodyDejavooBuyGiftCardOnline, IPayloadGiftCardOnline } from '../GiftCard/services/types/dejavoo';
import { Arrow, GiftCardItem, PageStyled } from '../GiftCard/styles';
import shopGiftCardApis from './services/apis';
import { SHOP_HEADER_API } from 'services/request';
type IBuyGiftCardFormValues = {
  name: string;
  email: string;
  phone: string;
  amount: string;
  message: string;
  cardType: string;
  cardName: string;
  card: string;
  expire: string;
  cv: string;
};

const ShopBuyGiftCardPage = () => {
  const inputNameRef = useRef<InputRef>(null);
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const detail = shopGiftCardSelectors.activeGiftCard();
  const configs = shopGiftCardSelectors.configs();

  useEffect(() => {
    setTimeout(() => { inputNameRef.current?.focus(); }, 100);
  }, []);

  const getFeeValue = (amount: number) => {
    if (!configs?.feeConfig) return 0;
    const { feeType, feeValue } = configs?.feeConfig || {};
    if (feeType === 'PERCENT') return (feeValue * amount) / 100;
    return feeValue;
  };

  const handleFinish = async (values: IBuyGiftCardFormValues) => {
    const phoneNumber = values.phone?.replace(/[^0-9]/g, '');
    const amount = parseFloat(values.amount || '');
    const feeValue = getFeeValue(amount);

    const body: IBodyDejavooBuyGiftCardOnline = {
      amount: amount + feeValue,
      customerName: values.name || '',
      email: values.email || '',
      giftCardTemplateId: id,
      message: values.message,
      phoneNumber: '+1' + phoneNumber,
    };
    loadingOverlayRef.current?.open();

    try {
      const res: AxiosResponse<{ payload: IPayloadGiftCardOnline }> = await shopGiftCardApis.buyGiftCardOnline(body);
      const resData = res?.data?.payload;
      if (resData) {
        window.location.replace(resData.information);
      } else {
        loadingOverlayRef.current?.close();
      }
    } catch (error) {
      loadingOverlayRef.current?.close();
    }
    finally { }
  };

  const submit = () => form.submit();

  const getFeeLabel = () => {
    if (!configs?.feeConfig) return '';
    const { feeType, feeValue } = configs?.feeConfig || {};
    if (feeType === 'PERCENT') return `Processing Fee: ${feeValue}%`;
    return `Processing Fee: ${formatCurrency(feeValue)}`;
  };

  const getPromotionLabel = () => {
    if (!configs?.promotionConfig) return '';
    const { minValue, promotionType, promotionValue } = configs.promotionConfig || {};
    const getValue = () => {
      if (promotionType === 'PERCENT') return `${promotionValue}%`;
      return `${formatCurrency(promotionValue)}`;
    };
    return `Purchase ${formatCurrency(minValue)} today, and receive additional ${getValue()} of gift value!`;
  };

  if (!detail) return <Navigate to={`/store/${SHOP_HEADER_API.shopId}`} replace />;

  return (
    <PageStyled className='container'>
      <div className="top-bar">
        <button onClick={() => navigate(`/store/${SHOP_HEADER_API.shopId}`)}>
          <Arrow />
        </button>
        <p className='title'>Happy Day Gift Card</p>
      </div>
      <div className='body'>
        <GiftCardItem className='btn gift-card-item'>
          <img src={detail?.urlImage} />
        </GiftCardItem>
        <Form form={form} onFinish={handleFinish} validateTrigger={['onSubmit']} layout='vertical'>
          <Row gutter={{ xs: 0, sm: 24 }}>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Your Name <span className='require-input'>(*)</span></>} name={'name'}
                rules={[{ required: true, message: 'Please enter your name' }]}
              >
                <Input autoFocus ref={inputNameRef} placeholder='Please enter...' tabIndex={1} type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'Email'} name={'email'}
                rules={[{ type: 'email' }]}
              >
                <Input placeholder='Please enter...' tabIndex={2} type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Phone <span className='require-input'>(*)</span></>} name={'phone'}
                rules={[{ required: true, message: 'Please enter your phone number' }]}
              >
                <PhoneInput tabIndex={3} type="text" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Amount <span className='require-input'>(*)</span></>} name={'amount'}
                extra={getPromotionLabel()}
                rules={[{ required: true, message: 'Please enter your amount' }]}
              >
                <CurrencyInput size='small' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'Message'} name={'message'}>
                <Input.TextArea rows={3} tabIndex={5} inputMode='text' />
              </Form.Item>
            </Col>
          </Row>

          <div className='button-submit-sm'>
            <Row className='buttons'>
              <Button className='button' onClick={submit}>
                <p className="text">{'PAY'}</p>
                <p className="text" style={{ fontSize: '12px' }}>{getFeeLabel()}</p>
              </Button>
            </Row>
          </div>
          <div className="sticky-bottom">
            <Row className='buttons'>
              <Button className='button button-submit-xs' onClick={submit}>
                <p className="text">{'PAY'}</p>
                <p className="text" style={{ fontSize: '12px' }}>{getFeeLabel()}</p>
              </Button>
            </Row>
          </div>
        </Form>
      </div>
    </PageStyled>
  );
};

export default ShopBuyGiftCardPage;
