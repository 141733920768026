import giftCardServiceSagas from 'features/shopping/GiftCard/services/sagas';
import shopGiftCardServiceSagas from 'features/shopping/GiftCardByShop/services/sagas';
import { all } from 'redux-saga/effects';
import shopServiceSagas from 'services/shop/sagas';
export default function* rootSaga() {
  yield all([
    shopServiceSagas(),
    giftCardServiceSagas(),
    shopGiftCardServiceSagas(),
  ]);
}
