import fetch from 'services/request';

export const getShopInfo = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/getInfoDetail',
    shopId,
  });
};

export const getStaffs = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/get-all-by-shop',
    params: {
      size: 1000,
    },
    shopId
  });
};


export const getCategories = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/category/get-all-by-category',
    shopId
  });
};

const shopApis = {
  getShopInfo,
  getStaffs,
  getCategories,
};

export default shopApis;
