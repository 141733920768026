import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IGiftCardTemplateItem } from './types/giftCard';
import { IGiftCardOnlineDetailResData } from 'features/shopping/GiftCard/services/types/dejavoo';

const getShopData = createAsyncAction<string>(PREFIX_ACTIONS + 'getShopData');

const getListGiftCards = createAsyncAction<undefined, IGiftCardTemplateItem[]>(PREFIX_ACTIONS + 'getListGiftCards');

const getGiftCardConfigs = createAsyncAction(PREFIX_ACTIONS + 'getGiftCardConfigs');

const getTransactionDetail = createAsyncAction<string, IGiftCardOnlineDetailResData>(PREFIX_ACTIONS + 'getTransactionDetail');


const shopGiftCardActions = {
  getShopData,
  getListGiftCards,
  getGiftCardConfigs,
  getTransactionDetail,
};

export default shopGiftCardActions;
