import styled from 'styled-components';

export const PageStyled = styled.div`
  padding: 0 5%;
  background: #F7F7F7;
  min-height: 100vh;
  .top-bar {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 0;
    .title {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
 
  .ant-form {
    width: 100%;
    label {
      color: var(--text-text-3, #1D2129);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .require-input {
        color: #F00;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .ant-form-item-control-input {
      .ant-input {
        border-radius: 5px;
        border: 1px solid var(--line-line-1, #DADADA);
        background: #FFF;
        display: flex;
        width: 100%;
        height: 46px;
        align-items: center;

        color: var(--text-text-2, #566A7F);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal; 
      }
      textarea.ant-input {
        height: unset;
      }
    }
    .ant-select-selector {
      border-radius: 5px;
      border: 1px solid var(--line-line-1, #DADADA);
      background: #FFF;
      display: flex;
      width: 100%;
      height: 46px;
      align-items: center;

      .ant-select-selection-search-input {
        color: var(--text-text-2, #566A7F);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        height: 100%;
      }
    }
  }

  .buttons {
    width: 100%;
    gap: 1.5rem;
    display: flex;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 2px;
    background: #0561AE;
    width: 100%;
    height: 60px;
    .text {
      color: var(--text-text-1, #FFF);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &.btn-back {
      border-radius: 2px;
      border: 1px solid var(--line-line-3, #86909C);
      background: var(--fill-fill-2, #E3E9ED);
      .text {
        color: #566A7F;
      }
    }
  }
  .button-submit-xs {
    display: none;
  }
  .sticky-bottom {
    display: none;
  }
 
  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    .top-bar {
      gap: 0.5rem;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
    .gift-card-item {
      margin-bottom: 1rem;
      width: 60%;
      padding: 8px;
      gap: 0px;
      img {
        border-radius: 8px;
      }
      .content {
        text-align: left;
        padding: 0.5rem 0.5rem;
        .name {
          font-size: 12px;
        }
        .desc {
          font-size: 10px;
        }
      }
    }
    .ant-form {
      width: 100%;
      label {
        font-size: 14px;
        .require-input {
          color: #F00;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .sticky-bottom {
      position: sticky;
      bottom:0;
      padding: 16px;
      padding-bottom: 1rem;
      background: #fff;
      margin: 0 -16px;
    }
    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 2px;
      background: #0561AE;
      width: 100%;
      height: 50px;
      .text {
        color: var(--text-text-1, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .button-submit-sm {
      display: none;
    }
    .button-submit-xs {
      display: flex;
    }

    .sticky-bottom {
      display: block;
    }

  }

  @media only screen and (max-width: 465px) {
    .gift-card-item.payment  {
      width: 100%;
    }
  }
`;

export const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
    <path d="M3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L9.65685 19.0711C10.0474 19.4616 10.6805 19.4616 11.0711 19.0711C11.4616 18.6805 11.4616 18.0474 11.0711 17.6569L5.41421 12L11.0711 6.34315C11.4616 5.95262 11.4616 5.31946 11.0711 4.92893C10.6805 4.53841 10.0474 4.53841 9.65685 4.92893L3.29289 11.2929ZM20 11L4 11V13L20 13V11Z" fill="#8E9BA9" />
  </svg>
);

export const GiftCardItem = styled.button`
border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 10px;
width: 60%;
display: flex;
flex-direction: column;
align-items: start;
text-align: left;
gap: 16px;
height: 20%;

  img {
    height: 100%;
    width: 100%;
  }
  .ant-image {
    width: 100%; 
    height: 9.375rem;  
  }
  .content {
    padding: 0.5rem 1rem;
  }
  .name {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .desc {
    color: var(--text-text-2, #566A7F);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.gift-card-img-result {
    height: auto;
    width: 100%;
    box-shadow: none;
    background: transparent;
    padding: 0;
  }
`;

export const GiftCardPaymentItem = styled.button`
border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 10px;
width: 60%;
display: flex;
flex-direction: column;
align-items: start;
text-align: left;
gap: 16px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
  .ant-image {
    width: 100%; 
    height: 9.375rem;  
  }
  .content {
    padding: 0.5rem 1rem;
  }
  .name {
    color: var(--text-text-3, #1D2129);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .desc {
    color: var(--text-text-2, #566A7F);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .box-value {
    width: 100%;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        color: var(--text-text-2, #566A7F);
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        line-height: normal;
      }
      .value {
        font-weight: 500;
        font-size: 1.125rem;
      }
    } 
    .total-row {
      p {
        color: #0561AE;
      }
      .value {
        font-weight: bold;
        font-size: 1.25rem;
      }
    }
  }
`;