import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';

const getShopInfo = createAsyncAction<string>(PREFIX_ACTIONS + 'getShopInfo');

const get = {
  staffs: createAsyncAction<string>(PREFIX_ACTIONS + 'getStaffList'),
  categories: createAsyncAction<string>(PREFIX_ACTIONS + 'getCategories'),
};

const init = {
  bookingOnline: createAction<string>(PREFIX_ACTIONS + 'initBookingOnline'),
};

const shopActions = {
  getShopInfo,
  get,
  init,
};

export default shopActions;

