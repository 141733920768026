import { LoadingFull } from 'components/Loading';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import GiftCardNotFound from './GiftCardNotFound';
import shopGiftCardActions from './services/actions';
import shopGiftCardSelectors from './services/selectors';

const ShopGiftCardWrapper = () => {
  const { shop_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const loading = shopGiftCardSelectors.getLoading();
  const data = shopGiftCardSelectors.getShopData();

  useEffect(() => {
    dispatch(shopGiftCardActions.getShopData.fetch(shop_id));
  }, []);

  if (loading) return <LoadingFull />;

  if (!data) return <GiftCardNotFound />;

  return <Outlet />;
};

export default ShopGiftCardWrapper;
