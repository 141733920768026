import { Button, Col, Form, Input, InputRef, Row, Select } from 'antd';
import { AxiosResponse } from 'axios';
import CardDateInput from 'components/CardDateInput';
import CardNumberInput from 'components/CardNumberInput';
import { LoadingFull } from 'components/Loading';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone } from 'utils/formatPhone';
import giftCardActions from './services/actions';
import giftCardApis from './services/apis';
import giftCardSelectors from './services/selectors';
import { IBodyAPIBuyGiftCard } from './services/types/api';
import { IBuyGiftCardResData } from './services/types/giftCard';
import { Arrow, GiftCardItem, GiftCardPaymentItem, PageStyled } from './styles';
type IBuyGiftCardFormValues = {
  name: string;
  email: string;
  phone: string;
  amount: string;
  message: string;
  cardType: string;
  cardName: string;
  card: string;
  expire: string;
  cvv: string;
};

const PaymentInfo = () => {
  const bodyPayment = giftCardSelectors.paymentInfo();
  const inputNameRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const detail = giftCardSelectors.detail();
  useEffect(() => {
    form.setFieldValue('cardType', 'MASTER_CARD');
  }, [bodyPayment]);

  useEffect(() => {
    setTimeout(() => {
      inputNameRef.current?.focus();
    }, 100);
  }, []);

  const handleBack = () => {
    // setStep(FORM_STEP.STEP_1);
    navigate(-1);
  };

  const handleFinish = async (values: IBuyGiftCardFormValues) => {
    setLoading(true);
    const postPaymentBody: IBodyAPIBuyGiftCard = {
      payload: {
        amount: bodyPayment?.amount || 0,
        cardName: values.cardName || '',
        cardNumber: values.card?.replace(/[^0-9]/g, '') || '',
        cardType: values.cardType,
        customerName: bodyPayment?.customerName || '',
        cvv: values.cvv,
        email: bodyPayment?.email || '',
        expireDate: values.expire,
        note: bodyPayment?.message || '',
        phoneNumber: bodyPayment?.phoneNumber || '',
        error: false,
      }
    };

    try {
      const res: AxiosResponse<IBuyGiftCardResData> = await giftCardApis.buyGiftCard(postPaymentBody);
      const result = res?.data?.payload;
      if (result) {
        dispatch(giftCardActions.setSuccessInfo({
          amount: postPaymentBody.payload.amount,
          authCode: result.authCode,
          giftCardCode: result.giftCardCode,
          transNum: result.transNum,
          customerName: postPaymentBody.payload.customerName,
          paymentMethod: postPaymentBody.payload.cardType,
        }));
        navigate('completed');
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  };


  if (!detail) return <Navigate to={'/'} replace />;

  if (loading) return <LoadingFull />;

  return (
    <PageStyled className='container'>
      <div className="top-bar">
        <button onClick={() => navigate('/')}>
          <Arrow />
        </button>
        <p className='title'>Happy Day Gift Card</p>
      </div>
      <div className='body'>
        {
          bodyPayment ?
            <GiftCardPaymentItem className='btn gift-card-item payment'>
              <img src={detail?.urlImage} />
              <div className="box-value">
                <div className="row total-row">
                  <p>Total Pay</p>
                  <p className="value">{formatCurrency(bodyPayment?.amount)}</p>
                </div>
                <div className="row">
                  <p>Name</p>
                  <p className="value">{bodyPayment?.customerName}</p>
                </div>
                <div className="row">
                  <p>Email</p>
                  <p className="value">{bodyPayment?.email}</p>
                </div>
                <div className="row">
                  <p>Phone</p>
                  <p className="value">{formatPhone(bodyPayment?.phoneNumber || '')}</p>
                </div>
                <div className="row">
                  <p>Message</p>
                  <p className="value">{bodyPayment?.message}</p>
                </div>
                <div className="row">
                  <p>Date</p>
                  <p className="value">{moment().format('MM-DD-YYYY')}</p>
                </div>
              </div>
            </GiftCardPaymentItem>
            :
            <GiftCardItem className='btn gift-card-item'>
              <img src={detail?.urlImage} />
            </GiftCardItem>
        }

        <Form form={form} onFinish={handleFinish} validateTrigger={['onSubmit']} layout='vertical'>
          <Row gutter={{ xs: 0, sm: 24 }}>
            <Col xs={24} sm={12}>
              <Form.Item label={<>Card type <span className='require-input'>(*)</span></>} name={'cardType'}
                rules={[{ required: true, }]}
              >
                <Select
                  defaultValue="MASTER_CARD"
                  options={[
                    { value: 'MASTER_CARD', label: 'Mastercard' },
                    { value: 'VISA', label: 'VISA' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'Name'} name={'cardName'}>
                <Input autoFocus ref={inputNameRef} placeholder='Please enter...' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'Card'} name={'card'}
                rules={[{ required: true, message: 'Please enter your card number' }]}
              >
                <CardNumberInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'Expire'} name={'expire'}
                rules={[{ required: true, message: 'Please enter your card expire date' }]}
              >
                <CardDateInput />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={'CVV'} name={'cvv'}
                rules={[{ required: true, message: 'Please enter your card expire date' }]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={3}
                  placeholder='***'
                  className='password-input'
                />
              </Form.Item>
            </Col>
          </Row>

          <div className='button-submit-sm'>
            <Row className='buttons'>
              <Button className='button btn-back' htmlType='button' onClick={handleBack}>
                <p className="text">BACK</p>
              </Button>
              <Button className='button' htmlType='submit'>
                <p className="text">{'PAY'}</p>
              </Button>
            </Row>
          </div>
          <div className="sticky-bottom">
            <Row className='buttons'>
              <Button className='button btn-back' htmlType='button' onClick={handleBack}>
                <p className="text">BACK</p>
              </Button>
              <Button className='button button-submit-xs' htmlType='submit'>
                <p className="text">{'PAY'}</p>
              </Button>
            </Row>
          </div>
        </Form>
      </div>
    </PageStyled>
  );
};

export default PaymentInfo;
