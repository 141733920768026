import Loading from 'components/Loading';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

type ILoadingOverlayRef = {
  open: () => void;
  close: () => void;
};
export const loadingOverlayRef = React.createRef<ILoadingOverlayRef>();
const LoadingOverlay = forwardRef<ILoadingOverlayRef>(({ }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  if (!open) return null;
  return (
    <LoadingPageStyled>
      <div className="box">
        <Loading />
      </div>
    </LoadingPageStyled>
  );
});
LoadingOverlay.displayName = 'LoadingOverlay';
export default LoadingOverlay;

const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;
