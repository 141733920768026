import fetch from 'services/request';
import { IBodyAPIBuyGiftCard } from './types/api';
import { IBodyDejavooBuyGiftCardOnline } from './types/dejavoo';

const buyGiftCard = (body: IBodyAPIBuyGiftCard) => {
  return fetch({
    method: 'post',
    url: '/api/v1/giftCard/buyGiftCardOnline',
    body,
  });
};

const getDetailGiftCard = (giftCardId: string) => {
  return fetch({
    method: 'get',
    url: `/api/v1/giftCard/getDetailGiftCard/${giftCardId}`,
  });
};

const buyGiftCardOnline = (body: IBodyDejavooBuyGiftCardOnline) => {
  return fetch({
    method: 'post',
    url: '/api/v1/giftCard/v2/buyOnline',
    body,
  });
};

const transactionDetail = (id: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/giftCard/transactionDetail/' + id,
    autoToast: false,
  });
};

const getGiftCardConfigs = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/giftCard/config',
  });
};

const giftCardApis = {
  buyGiftCard,
  getDetailGiftCard,
  buyGiftCardOnline,
  transactionDetail,
  getGiftCardConfigs,
};

export default giftCardApis;
