
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import { SHOP_HEADER_API } from 'services/request';
import { IResDataBody } from 'services/response';
import actions from './actions';
import apis from './apis';
import { IGiftCardTemplateItem } from './types/giftCard';
import { IShopInfoResData } from './types/shop';
import { IGiftCardConfigs } from 'features/shopping/GiftCard/services/types/giftCard';
import { IGiftCardOnlineDetailResData } from 'features/shopping/GiftCard/services/types/dejavoo';
import { isEmpty } from 'lodash';

const getListGiftCards = function* () {
  try {
    const res: IResDataBody<IGiftCardTemplateItem[]> = yield call(apis.getListGiftCards);
    if (res.data.payload) {
      yield put(actions.getListGiftCards.success(res.data.payload));
    }
  } catch (error) { }
};

const getTransactionDetail: ISagaFunc<string> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: IResDataBody<IGiftCardOnlineDetailResData> = yield call(apis.transactionDetail, payload);
    if (!isEmpty(res.data.payload)) {
      yield put(actions.getTransactionDetail.success(res.data.payload));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getTransactionDetail.fail({}));
  }
};

const getGiftCardConfigs = function* () {
  try {
    const res: IResDataBody<IGiftCardConfigs> = yield call(apis.getGiftCardConfigs);
    if (res.data.payload) {
      yield put(actions.getGiftCardConfigs.success(res.data.payload));
    }
  } catch (error) { }
};


const getShopData: ISagaFunc<string> = function* ({ payload }) {
  SHOP_HEADER_API.shopId = payload;
  yield delay(200);
  yield all([
    put(actions.getListGiftCards.fetch()),
    put(actions.getGiftCardConfigs.fetch()),
  ]);
  try {
    const res: IResDataBody<IShopInfoResData> = yield call(apis.getShopDetail);
    if (res.data.payload) {
      yield put(actions.getShopData.success(res.data.payload));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getShopData.fail({}));
  }
};

export default function* shopGiftCardServiceSagas() {
  yield takeLatest(actions.getShopData.fetch, getShopData);
  yield takeLatest(actions.getListGiftCards.fetch, getListGiftCards);
  yield takeLatest(actions.getGiftCardConfigs.fetch, getGiftCardConfigs);
  yield takeLatest(actions.getTransactionDetail.fetch, getTransactionDetail);
}
