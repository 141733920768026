import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
type MyState = RootState['shopGiftCard'];
const getCurrentState = (state: RootState): MyState => state['shopGiftCard'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getShopData = () => selector('shopData') as MyState['shopData'];
const getLoading = () => selector('loading') as MyState['loading'];
const getListGiftCards = () => selector('giftCards') as MyState['giftCards'];
const activeGiftCard = () => selector('activeGiftCard') as MyState['activeGiftCard'];
const configs = () => selector('configs') as MyState['configs'];
const loadingDetail = () => selector('loadingDetail') as MyState['loadingDetail'];
const detail = () => selector('detail') as MyState['detail'];

const shopGiftCardSelectors = {
  getShopData,
  getLoading,
  getListGiftCards,
  activeGiftCard,
  configs,
  detail,
  loadingDetail,
};
export default shopGiftCardSelectors;
