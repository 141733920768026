import colorTheme from 'constants/color';
import React, { CSSProperties, useMemo } from 'react';
import { css, styled } from 'styled-components';
import { ISpacing, generateCssSpacing } from 'styles/spacing';
const TEXT_VARIANT_TYPES = {
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',
  H7: 'H7',
  H8: 'H8',
  H9: 'H9',
  CONTENT_1: 'CONTENT_1',
  CONTENT_2: 'CONTENT_2',
  BODY_1: 'BODY_1',
  BODY_2: 'BODY_2',
  CAPTION_1: 'CAPTION_1',
  CAPTION_2: 'CAPTION_2',
  CAPTION_3: 'CAPTION_3',
  CAPTION_4: 'CAPTION_4',
  WELCOME: 'WELCOME',
  LINK: 'LINK',
  TEXT_LINK: 'TEXT_LINK',
};

export interface ITextProps extends ISpacing {
  id?: string;
  inline?: boolean;
  variant?: keyof typeof TEXT_VARIANT_TYPES;
  children?: React.ReactNode;
  className?: string;
  color?: keyof typeof colorTheme;
  textDecorate?: string;
  textAlign?: CSSProperties['textAlign'];
  whiteSpace?: CSSProperties['whiteSpace'];
  margin?: CSSProperties['margin'];
  style?: CSSProperties,
  wrapWidth?: string;
  textTransform?: CSSProperties['textTransform'];
  printMode?:boolean;
}
const Text: React.FC<ITextProps> = ({
  id,
  color,
  variant = 'BODY_1',
  children = null,
  className,
  textDecorate,
  textAlign,
  wrapWidth,
  printMode = false,
  ...props
}) => {
  const styles: React.CSSProperties = useMemo(
    () => {
      return ({
        ...generateCssSpacing(props),
        width: wrapWidth,
        textTransform: props.textTransform,
        whiteSpace: props.whiteSpace,
        ...props.style ?? {},
      });
    },
    [props]
  );

  return (
    <TextStyled
      id={id}
      textAlign={textAlign}
      textDecorate={textDecorate}
      color={color}
      className={`${variant} ${className ?? ''} ${printMode ? 'print-text': ''}`}
      style={styles}
    >
      {children}
    </TextStyled>
  );
};

export default Text;

const TEXT_VARIANT_CSS = {
  [TEXT_VARIANT_TYPES.H1]: `
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H2]: `
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H3]: `
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H4]: `
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H5]: `
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H6]: `
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H7]: `
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H8]: `
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.H9]: `
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;
    text-transform: capitalize;
  `,

  [TEXT_VARIANT_TYPES.CONTENT_1]: `
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.CONTENT_2]: `
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,

  [TEXT_VARIANT_TYPES.BODY_1]: `
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.BODY_2]: `
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,

  [TEXT_VARIANT_TYPES.CAPTION_1]: `
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  `,
  [TEXT_VARIANT_TYPES.CAPTION_2]: `
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  `,
  [TEXT_VARIANT_TYPES.CAPTION_3]: `
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.CAPTION_4]: `
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,

  [TEXT_VARIANT_TYPES.WELCOME]: `
    font-family: Antic Didone;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 6.25rem;
  `,
  [TEXT_VARIANT_TYPES.LINK]: `
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
  [TEXT_VARIANT_TYPES.TEXT_LINK]: `
    color: var(--info-infor-3, #6FABB6);
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-thickness: from-font;
  `,
};

type TextStyledProps = {
  color?: ITextProps['color'];
  textDecorate?: string;
  textAlign?: ITextProps['textAlign'];
  margin?: ITextProps['margin'];

};

const textStyledCSS = css<TextStyledProps>`
  text-align: ${({ textAlign }) => textAlign || 'unset'};
  color: ${({ color }) => (color ? colorTheme[color] : '#1D2129')};
  text-decoration: ${({ textDecorate }) => textDecorate || 'none'};
  margin: ${({ margin }) => margin || '0'};
`;

const TextStyled = styled.div.withConfig({
  shouldForwardProp: (prop) => !['textDecorate', 'textAlign', 'color'].includes(prop),
}) <TextStyledProps>`
  ${textStyledCSS}
  ${Object.entries(TEXT_VARIANT_CSS).map(
  ([key, css]) => `
      &.${key} {
        ${css}
      }
    `
)}
  &.print-text{
    font-family: 'Roboto', serif !important;
    color: black !important;
    padding: 1px 0px;
  }
`;
