import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const CardNumberInput = (props: any) => {
  return (
    <CardNumberInputStyled>
      <MaskedInput
        {...props}
        className={'phone-input ' + props.className}
        mask={
          [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
        }
        placeholder='**** **** **** ****'
      />
    </CardNumberInputStyled>
  );
};

export default CardNumberInput;

const CardNumberInputStyled = styled.div`
display: flex;
flex:1;
input {
  border-radius: 5px;
  border: 1px solid var(--line-line-1, #DADADA);
  background: #FFF;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0px 11px;

  color: var(--text-text-2, #566A7F);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal; 
  outline: none;
  &:focus, &.hover {
    outline: none;
  }
}
.ant-select:hover, .ant-select:focus {
  .ant-select-selector {
    border-color: var(--line-line-1, #DADADA) !important;
  }
}
.ant-select-selector {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.phone-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
`;