import styled from 'styled-components';
import React from 'react';
import shopGiftCardSelectors from './services/selectors';
import { Navigate, useNavigate } from 'react-router-dom';
import { SHOP_HEADER_API } from 'services/request';

const ShopBuyGiftCardFail = ({ }) => {
  const navigate = useNavigate();
  const detail = shopGiftCardSelectors.getShopData();
  const onBackToHome = () => {
    if (detail?.website) {
      return window.location.replace(detail?.website || '');
    }
    navigate(`/store/${SHOP_HEADER_API.shopId}`, { replace: true });
  };

  if (!detail) return <Navigate to={`/store/${SHOP_HEADER_API.shopId}`} replace />;
  return (
    <FailStyled>
      <div className="box">
        <div className="header">
          <FailIcon />
          <div className="title">Payment Failed!</div>
        </div>
        <div className="divider" />
        <div className="bottom">
          <div className="highlight">
            Unfortunately, the payment for <br />
            {'this order has failed!'}
          </div>
          <div className="content">An issuing bank will often decline an attempt to charge a card if the name, expiry date, or post code you entered doesn’t match the bank’s information.</div>
          <div className="divider" />
          <button className='btn btn-primary' onClick={onBackToHome}>
            <p className="text">{'Back to home'}</p>
          </button>
        </div>
      </div>
    </FailStyled>
  );
};

export default ShopBuyGiftCardFail;

const FailStyled = styled.div`
background: #F7F7F7;
min-height: 100vh;
padding: 1.5rem 1rem;

  .box {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    gap: 32px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    
    .title {
      color: var(--text-text-3, #1D2129);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%);
    background-position: bottom;
    background-size: 16px 1px;
    background-repeat: repeat-x;
  }
  .bottom {
    color: var(--text-text-2, #566A7F);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: flex;
    flex-direction: column;

    .highlight {
      color: var(--text-text-3, #1D2129);
      text-align: center;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 1rem;
    }
    .divider {
      background: #DADADA;
      width: 100%;
      height: 1px;
      margin: 1rem 0;
    }

    button {
      border-radius: 2px;
      background: #0561AE;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      .text {
        color: var(--text-text-1, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;

const FailIcon = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28.5" cy="28" r="28" fill="#A22323" fillOpacity="0.12" />
    <circle cx="27.835" cy="28.335" r="13.335" fill="#D61A1A" />
    <path d="M23.5 23.293L33.5 33.293" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M33.5 23L23.5 33" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);