import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  shopInfo: null,
  staffs: [],
  categories: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.get.categories.success, (state, { payload }) => {
        state.categories = payload;
      })
      .addCase(actions.get.staffs.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.getShopInfo.success, (state, { payload }) => {
        state.shopInfo = payload;
      })
      ;
  },
});

const shopServiceReducer = Slice.reducer;
export default shopServiceReducer;
