import { IBodyDejavooBuyGiftCardOnline } from 'features/shopping/GiftCard/services/types/dejavoo';
import { shopFetch } from 'services/request';
const fetch = shopFetch;
const getShopDetail = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/detail',
  });
};
const getListGiftCards = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/giftCard/templates-online',
  });
};

const getGiftCardConfigs = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/giftCard/config',
  });
};

const buyGiftCardOnline = (body: IBodyDejavooBuyGiftCardOnline) => {
  return fetch({
    method: 'post',
    url: '/api/v1/giftCard/v2/buyOnline',
    body,
  });
};

const transactionDetail = (id: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/giftCard/transactionDetail/' + id,
    autoToast: false,
  });
};

const shopGiftCardApis = {
  getShopDetail,
  getListGiftCards,
  getGiftCardConfigs,
  buyGiftCardOnline,
  transactionDetail,
};

export default shopGiftCardApis;
