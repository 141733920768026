import { call, delay, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import apis from './apis';
import { AxiosResponse } from 'axios';
import { IGiftCardConfigs } from './types/giftCard';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';

const getGiftCardConfigs = function* () {
  yield setLoading(PATH_LOADING.getGiftCardConfigs, true);
  yield delay(200);
  try {
    const res: AxiosResponse<{ payload: IGiftCardConfigs }> = yield call(apis.getGiftCardConfigs);
    if (res.data.payload) {
      yield put(actions.getGiftCardConfigs.success(res.data.payload));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getGiftCardConfigs, false);
  }
};

export default function* giftCardServiceSagas() {
  yield takeLatest(actions.getGiftCardConfigs.fetch, getGiftCardConfigs);
}
