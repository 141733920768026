import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';

export type MyState = RootState['shop'];
export const getCurrentState = (state: RootState): MyState => state['shop'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getShopInfo = () => selector('shopInfo') as MyState['shopInfo'];

const getShopInfoLoading = () => uiSelector.getLoading(PATH_LOADING.getShopInfo) as boolean;

const data = {
  staffs: () => selector('staffs') as MyState['staffs'],
  categories: () => selector('categories') as MyState['categories'],
};

const shopSelectors = {
  getShopInfo,
  getShopInfoLoading,
  data,
};

export default shopSelectors;
