import { QRCode } from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import giftCardApis from './services/apis';
import { IGiftCardDetailResData, IPaymentGiftCardInfo } from './services/types/giftCard';
import { GiftCardItem } from './styles';
import giftCardImg from './img/giftCardImg.jpg';

const GiftCardDetail = ({ }) => {
  const { id = '' } = useParams();
  const setLoadingPage = useSetLoadingPage();
  const [result, setData] = useState<IPaymentGiftCardInfo | null>(null);
  const date = useMemo(() => moment(result?.date), [result]);

  const fetchData = async () => {
    setLoadingPage(true);
    try {
      const res: AxiosResponse<{ payload: IGiftCardDetailResData }> = await giftCardApis.getDetailGiftCard(id);
      const _data = res.data.payload;
      if (res.data.payload) {
        const data: IPaymentGiftCardInfo = {
          amount: _data.amount,
          date: _data.createDate,
          authCode: '',
          customerName: _data.customerNameTo,
          giftCardCode: _data.giftCardCode,
          paymentMethod: 'Credit Card',
          transNum: '',
        };
        setData(data);
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Page>
        <div className='box' style={{
          'borderRadius': '12px',
          'background': '#FFF',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
          padding: '24px',
        }}>
          <GiftCardItem className='btn gift-card-item gift-card-img-result'>
            <img src={giftCardImg} />
          </GiftCardItem>
          <div style={{ margin: '32px 0' }}>
            <div className='circle' />
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
            }} />
            <div className='circle' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Date:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{date.format('ddd, MM-DD-YYYY')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Time:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{date.format('HH:mm A')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Payment Method:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.paymentMethod || 'Credit Card'}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Name On Card:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.customerName || ''}</span>
            </div>
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
              margin: '2px 0',
            }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Amount:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{formatCurrency(parseFloat(String(result?.amount ?? '')))}</span>
            </div>
          </div>
          <div style={{ height: 1, width: '100%', background: '#DADADA', margin: '16px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>QR Code:</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <QRCode size={75} value={result?.giftCardCode?.toString() || ''} />
              <div>
                {result?.giftCardCode}
              </div>
            </div>
          </div>
        </div>
      </Page >
    </>
  );
};

export default GiftCardDetail;

const Page = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  .box {
    width: 100%;
    max-width: 20rem;
  }
  @media only screen and (max-width: 600px) {
    .box {
      width: 100%;
      max-width: unset;
    } 
  }
`;