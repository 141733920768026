import giftCardServiceReducer from 'features/shopping/GiftCard/services/reducers';
import shopGiftCardServiceReducer from 'features/shopping/GiftCardByShop/services/reducers';
import uiServiceReducer from 'services/UI/reducer';
import shopServiceReducer from 'services/shop/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  shop: shopServiceReducer,
  giftCard: giftCardServiceReducer,
  shopGiftCard: shopGiftCardServiceReducer,
};

export default rootReducer;
