import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IBodyPaymentGiftCard } from './types/api';
import { IPaymentGiftCardInfo } from './types/giftCard';

const getGiftCardToBuy = createAsyncAction<string>(PREFIX_ACTIONS + 'getGiftCardToBuy');

const getGiftCardDetail = createAction(PREFIX_ACTIONS + 'getGiftCardDetail');

const getGiftCardConfigs = createAsyncAction(PREFIX_ACTIONS + 'getGiftCardConfigs');

const setPaymentInfo = createAction<IBodyPaymentGiftCard | null>(PREFIX_ACTIONS + 'setPaymentInfo');

const setSuccessInfo = createAction<IPaymentGiftCardInfo | null>(PREFIX_ACTIONS + 'setSuccessInfo');

const giftCardActions = {
  getGiftCardToBuy,
  getGiftCardDetail,
  setPaymentInfo,
  setSuccessInfo,
  getGiftCardConfigs,
};

export default giftCardActions;

