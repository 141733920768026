import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
const mask = [/\d/, /\d/, '/', /\d/, /\d/];
const hint = 'MM/YY';
const pipe = createAutoCorrectedDatePipe('mm/yy');

const getValue = value => value;

class CardDateInput extends React.Component {

  static defaultProps = {};

  getValue = () => {
    if (!this.input) {
      return null;
    }

    return this.input.getValue();
  };

  triggerFocus = () => this.input.FormComponent.triggerFocus();

  render() {
    return (
      <CardNumberInputStyled>
        <MaskedInput
          mask={mask}
          maskHint={hint}
          pipe={pipe}
          getValue={getValue}
          ref={ref => (this.input = ref)}
          {...this.props}
          placeholder='mm/yy'
        />
      </CardNumberInputStyled>
    );
  }
}

export default CardDateInput;


const CardNumberInputStyled = styled.div`
display: flex;
flex:1;
input {
  border-radius: 5px;
  border: 1px solid var(--line-line-1, #DADADA);
  background: #FFF;
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  padding: 0px 11px;

  color: var(--text-text-2, #566A7F);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal; 
  outline: none;
  &:focus, &.hover {
    outline: none;
  }
}
.ant-select:hover, .ant-select:focus {
  .ant-select-selector {
    border-color: var(--line-line-1, #DADADA) !important;
  }
}
.ant-select-selector {
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.phone-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
`;